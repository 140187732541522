








































import { mapActions, mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { roundNumber } from '@/helpers/roundNumber';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import type {
  AvgNgrStatsItem,
  AvgUsersNGRStatsItem,
  FeatureFlagItem
} from '@/api/schema';
import VueApexCharts from 'vue-apexcharts';

const defaultDateData = {
  from: DateTime.utc()
    .minus({ day: 1 })
    .minus({ months: 1 })
    .toFormat('yyyy-LL-dd'),
  to: DateTime.utc().minus({ day: 1 }).toFormat('yyyy-LL-dd')
};

export default {
  name: 'DashboardGraphs',
  data: (): any => ({
    selectedGraph: 0,
    dates: { ...defaultDateData },
    userNgrDateRequest: {
      from: DateTime.now()
        .minus({ day: 1 })
        .minus({ month: 6 })
        .toFormat('yyyy-LL-dd'),
      to: DateTime.utc().minus({ day: 1 }).toFormat('yyyy-LL-dd')
    },
    selectData: [
      { value: 0, text: 'Users' },
      { value: 1, text: 'NGR per user' }
    ],
    loading: false
  }),
  components: {
    VExtendedDataPicker,
    VueApexCharts
  },
  computed: {
    ...mapState('Dashboard', ['NGRData', 'usersStakeData']),
    ...mapState('FeatureFlags', ['features']),
    ...mapGetters('Onboarding', ['operatorId']),
    disableCharts(): boolean {
      return (
        this.features.find(
          (v: FeatureFlagItem) => v.name === 'dashboard_charts'
        )?.enabled === false
      );
    },
    chartOptions(): unknown {
      const data =
        this.selectedGraph === 0 ? this.usersStakeData : this.NGRData;
      const categories = data.map(
        (item: AvgUsersNGRStatsItem | AvgNgrStatsItem) => item?.date
      );

      return {
        noData: {
          text: 'The data will be available soon',
          style: {
            color: 'rgba(58, 53, 65, 0.3)',
            fontSize: '24px',
            fontFamily: 'Inter'
          }
        },
        stroke: {
          width: 4,
          curve: 'straight'
        },
        markers: {
          size: [5, 6]
        },
        grid: {
          show: !!data.length
        },
        chart: {
          type: this.selectedGraph === 0 ? 'line' : 'bar',
          toolbar: {
            show: false
          }
        },
        colors: ['#FF5252', '#696CFF'],
        legend: {
          position: 'top',
          markers: {
            radius: 0,
            offsetX: -4
          },
          itemMargin: {
            horizontal: 10
          }
        },
        xaxis: {
          categories: categories,
          tooltip: {
            enabled: false
          },
          labels: {
            show: !!data.length,
            rotate: 0,
            style: {
              colors: '#A1ABBF'
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          axisTicks: {
            color: '#A1ABBF'
          },
          labels: {
            rotate: 0,
            style: {
              colors: '#A1ABBF'
            },
            minWidth: 30,
            formatter: (v) => {
              return roundNumber(v, 6, 3);
            }
          }
        }
      };
    },
    ngrSeries(): unknown[] {
      return [
        this.getSeries(this.NGRData, 'nonStakeNgr', 'Users W/O Stakes'),
        this.getSeries(this.NGRData, 'stakeNgr', 'Users With Stakes')
      ];
    },
    usersNGRSeries(): unknown[] {
      return [
        this.getSeries(
          this.usersStakeData,
          'nonStakeUsersCount',
          'Users W/O Stakes'
        ),
        this.getSeries(
          this.usersStakeData,
          'stakeUsersCount',
          'Users With Stakes'
        )
      ];
    },
    series(): unknown[] {
      return this.loading ? [] : [this.usersNGRSeries, this.ngrSeries];
    },

    minDate(): string {
      return DateTime.utc().minus({ year: 1 }).toFormat('yyyy-LL-dd');
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getData();
        }
      },
      immediate: true
    },
    selectedGraph: 'getData',
    dates: 'getData'
  },
  methods: {
    ...mapActions('Dashboard', ['getNGRHistory', 'getUserStakeStats']),
    async getData(): Promise<void> {
      this.loading = true;

      if (this.selectedGraph === 0) {
        await this.getUserStakeStats(this.dates);
        this.loading = false;
        return;
      }

      await this.getNGRHistory(this.userNgrDateRequest);
      this.loading = false;
    },
    getSeries(
      data: unknown[],
      valueProp: string,
      name: string
    ): { name: string; data: string[] } {
      return {
        name,
        data: data.map(
          (item: AvgNgrStatsItem | AvgUsersNGRStatsItem) => item[valueProp]
        )
      };
    }
  }
};
